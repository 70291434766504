import React, {useEffect, useState} from 'react';
import logo from './img.webp';
import './App.css';
const axios = require('axios');


function App() {

    const [content, setContent] = useState(null);
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [showFile, setShowFile] = useState(true);

    const makePostRequest = async () => {
        let res = await axios.post('https://ql6y6ydew4.execute-api.us-west-2.amazonaws.com/default/whatsapp_stats', content);
        setData(res.data);
        setContent(null);
        return res
    }

    useEffect(() => {
        if (content){
            makePostRequest().then(r => console.log(r))
        }
    }, [content])

    useEffect(() => {
        let arr = [];
        if (data){
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    arr.push({name: key, count: data[key]});
                }
            }

        }
        setTableData(arr.sort(dynamicSort("-count")));
    }, [data])

    const dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const onFileChange = (files) => {
        setShowFile(false);
        // Loop through the FileList and render image files as thumbnails.
        for (let i = 0, f; f = files[i]; i++) {

            var reader = new FileReader();
            reader.onload = function(e) {
                // The file's text will be printed here
                setContent(e.target.result)
            };

            reader.readAsText(f);
        }
    }

    const renderTableData = () => {
        return tableData.map((person, index) => {
            const { name, count } = person //destructuring
            return (
                <tr key={name}>
                    <td>{name}</td>
                    <td>{count}</td>
                </tr>
            )
        })
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <br/>
                <p>
                    Ramadaan Mubarak
                </p>
                <p>
                    Below are the live stats for this chat:
                </p>
                <br/>
                {
                    data ?
                        <table id='people'>
                            <tbody>
                                {renderTableData()}
                            </tbody>
                        </table>
                    :
                        (showFile ? <input type="file" onChange={(e) => onFileChange(e.target.files)} /> : null)
                }
            </header>
        </div>
    );
}

export default App;

